const Auth = {
    isLoggedIn() {
        return localStorage.getItem('token') !== null;
    },
    requireAuth(nextState, replace) {
        if (!this.isLoggedIn()) {
            replace({
                pathname: '/login',
                state: { nextPathname: nextState.location.pathname }
            });
        }
    },
    user() {
        const user = localStorage.getItem('user');
        return user ? JSON.parse(user) : null;
    },
    company() {
        const company = localStorage.getItem('company');
        return company ? JSON.parse(company) : null;
    },
    token() {
        return localStorage.getItem('token');
    }
}

export default Auth;
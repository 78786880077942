import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Paper,
  Typography, 
  TextField, 
  Button, 
  Box, 
  Tabs, 
  Tab, 
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import Auth from '../components/Auth';
import { useSnackbar } from '../contexts/SnackbarContext';
import { MuiColorInput } from 'mui-color-input';
import CircleIcon from '@mui/icons-material/Circle';

const CompanySettings = () => {
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const { showSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (tabIndex === 0) {
      setCompanyData({ ...companyData, [name]: value });
    } else if (tabIndex === 1) {
      // setUserData({ ...userData, [name]: value });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  /* Company */
  const [companyData, setCompanyData] = useState({
    name: Auth.company().name,
  });

  const handleCompanySave = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch(process.env.REACT_APP_API_URL + '/me/company', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Auth.token()}`
      },
      body: JSON.stringify(companyData),
    })
      .then(response => response.json())
      .then(data => {
        // if (data.id === user.company.id) {
        //   user.company = data;
        //   localStorage.setItem('user', JSON.stringify(user));
        //   localStorage.setItem('accountingYear', data.accounting_years[0].id);
        //   setSuccess('Dine ændringer er gemt');
        // } else {
        //   let errorMessage = '';
        //   for (const key in data) {
        //     errorMessage += data[key][0] + '\r\n';
        //   }
        //   setError('Udfyld alle felter og prøve igen:\r\n' + errorMessage);
        // }
      })
      .catch((error) => {
        showSnackbar('Der opstod en fejl under gemning af klub indstillinger', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* Aircraft */
  const [aircraftData, setAircraftData] = useState([]);
  const [selectedAircraft, setSelectedAircraft] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/me/company/aircraft', {
      headers: {
        'Authorization': `Bearer ${Auth.token()}`
      }
    })
      .then(response => response.json())
      .then(data => {
        setAircraftData(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const handleAircraftNew = (aircraft) => {
    setSelectedAircraft(aircraft);
  };

  const handleAircraftEdit = (aircraft) => {
    setSelectedAircraft(aircraft);
  };

  const handleAircraftSave = async () => {
    setLoading(true);

    const method = selectedAircraft.id ? 'PATCH' : 'POST';
    fetch(process.env.REACT_APP_API_URL + '/me/company/aircraft' + (method === 'PATCH' ? `/${selectedAircraft.id}` : ''), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Auth.token()}`
      },
      body: JSON.stringify(selectedAircraft),
    })
      .then(response => response.json())
      .then(data => {
        if (data.id) {
          if (method === 'PATCH') {
            setAircraftData(aircraftData.map(item => item.id === data.id ? data : item));
          } else {
            setAircraftData([...aircraftData, data]);
          }
          showSnackbar('Luftfartøj er ' + (method === 'PATCH' ? 'gemt' : 'oprettet'), 'success');
        } else {
          showSnackbar('Der opstod en fejl under gemning af luftfartøj', 'error');
        }
      }).finally(() => {
        setLoading(false)
      });

    // Save aircraft data logic
    setSelectedAircraft(null);
  };
  
  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 3 }}>
        Klub indstillinger
      </Typography>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="settings tabs" sx={{ mb: 2 }}>
        <Tab label="Klub" />
        <Tab label="Luftfartøjer" />
      </Tabs>
      <Paper sx={{ padding: 4 }}>
        {tabIndex === 0 && (
          <form onSubmit={handleCompanySave}>
              <TextField
                required
                margin="normal"
                fullWidth
                label="Navn"
                name="name"
                value={companyData.name}
                onChange={handleChange}
                disabled={loading}
              />
            
              <Button variant="contained" color="primary" type="submit" disabled={loading}>
                Gem ændringer
              </Button>
          </form>
        )}
        {tabIndex === 1 && (
          <>
            <Box mb={2}>
              <Button variant="contained" color="primary" onClick={() => handleAircraftNew({ id: null, name: '' })}>
                Opret ny
              </Button>
            </Box>
            <List>
              {aircraftData.map((aircraft) => (
                <ListItemButton key={aircraft.id} onClick={() => handleAircraftEdit(aircraft)}>
                  <ListItemIcon>
                    <CircleIcon style={{ color: aircraft.color }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary={`${aircraft.name} (${aircraft.model})`}
                    secondary={`Tacho Time: ${aircraft.total_tacho_time}, Flight Time: ${aircraft.total_flight_time}`} />
                </ListItemButton>
              ))}
            </List>
          </>
        )}
      </Paper>

      <Dialog open={!!selectedAircraft} onClose={() => setSelectedAircraft(null)}>
        <DialogTitle>
          {selectedAircraft && selectedAircraft.id ? 'Rediger luftfartøj' : 'Opret luftfartøj'}
        </DialogTitle>
        <DialogContent>
          {selectedAircraft && (
            <>
              <TextField
                required
                fullWidth
                margin="normal"
                label="Name"
                value={selectedAircraft.name}
                onChange={(e) => setSelectedAircraft({ ...selectedAircraft, name: e.target.value })}
                disabled={loading}
              />
              <TextField
                required
                fullWidth
                margin="normal"
                label="Model"
                value={selectedAircraft.model}
                onChange={(e) => setSelectedAircraft({ ...selectedAircraft, model: e.target.value })}
                disabled={loading}
              />
              <TextField
                required
                fullWidth
                margin="normal"
                label="Seats"
                type="number"
                value={selectedAircraft.seats}
                onChange={(e) => setSelectedAircraft({ ...selectedAircraft, seats: e.target.value })}
                disabled={loading}
              />
              <TextField
                required
                fullWidth
                margin="normal"
                label="Price Tacho Time"
                type="number"
                value={selectedAircraft.price_tacho_time}
                onChange={(e) => setSelectedAircraft({ ...selectedAircraft, price_tacho_time: e.target.value })}
                disabled={loading}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Price Flight Time"
                type="number"
                value={selectedAircraft.price_flight_time}
                onChange={(e) => setSelectedAircraft({ ...selectedAircraft, price_flight_time: e.target.value })}
                disabled={loading}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Fuel Burn"
                type="number"
                value={selectedAircraft.fuel_burn}
                onChange={(e) => setSelectedAircraft({ ...selectedAircraft, fuel_burn: e.target.value })}
                disabled={loading}
              />
              <TextField
                required
                fullWidth
                margin="normal"
                label="Total Tacho Time"
                type="number"
                value={selectedAircraft.total_tacho_time}
                onChange={(e) => setSelectedAircraft({ ...selectedAircraft, total_tacho_time: e.target.value })}
                disabled={loading}
              />
              <TextField
                required
                fullWidth
                margin="normal"
                label="Total Flight Time"
                type="number"
                value={selectedAircraft.total_flight_time}
                onChange={(e) => setSelectedAircraft({ ...selectedAircraft, total_flight_time: e.target.value })}
                disabled={loading}
              />
              <MuiColorInput
                required
                fullWidth
                margin="normal"
                format="hex"
                value={selectedAircraft.color}
                onChange={(color) => setSelectedAircraft({ ...selectedAircraft, color: color })}
                disabled={loading}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedAircraft(null)} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleAircraftSave} color="primary" disabled={loading}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CompanySettings;
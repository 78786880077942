import React, { useState, useEffect } from 'react';
// import { Box, Button, Container, Typography, Grid, Paper, Skeleton, Avatar, Divider } from '@mui/material';
import { Scheduler } from "@aldabil/react-scheduler";
import Auth from '../components/Auth';

// https://github.com/aldabil21/react-scheduler

const Booking = () => {
  const [aircraftData, setAircraftData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/me/company/aircraft', {
      headers: {
        'Authorization': `Bearer ${Auth.token()}`
      }
    })
      .then(response => response.json())
      .then(data => {
        setAircraftData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);

      });
  }, []);

  const fetchBookings = async () => {
    return new Promise((res) => {
      fetch(process.env.REACT_APP_API_URL + '/me/company/bookings', {
        headers: {
          'Authorization': `Bearer ${Auth.token()}`
        }
      })
        .then(response => response.json())
        .then(data => {
          let bookings = [];
          data.forEach(booking => {
            bookings.push({
              disabled: false,
              event_id: booking.id,
              title: booking.user.name + ' (' + booking.aircraft.name + ')',
              start: new Date(booking.start),
              end: new Date(booking.end),
              color: booking.aircraft.color,
              aircraft_id: booking.aircraft.id,
              expected_flight_time: booking.expected_flight_time,
              comment: booking.comment || '',
              draggable: false

              // textColor: booking.textColor,
              // editable: booking.editable,
              // deletable: booking.deletable,
              // allDay: booking.allDay,
            });
          });

          res(bookings);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    });
  };

  if (loading) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <Scheduler
      view='day'
      hourFormat='24'
      // timeZone='Europe/Copenhagen'
      day={{
        startHour: 0,
        endHour: 24,
        step: 30,
      }}
      getRemoteEvents={fetchBookings}
      onConfirm={async (event, action) => {
        if (action === 'create') {
          const response = 
            await fetch(process.env.REACT_APP_API_URL + '/me/company/bookings', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Auth.token()}`
              },
              body: JSON.stringify({
                start: event.start,
                end: event.end,
                aircraft_id: event.aircraft_id,
                expected_flight_time: event.expected_flight_time,
                comment: event.comment || '',
                seats: event.seats || 0
              })
            })
            .then(response => response.json())
            .catch((error) => {
              console.error('Error:', error)
            });

          if (response.id) {
            return {
              disabled: false,
              event_id: response.id,
              title: response.user.name + ' (' + response.aircraft.name + ')',
              start: new Date(response.start),
              end: new Date(response.end),
              color: response.aircraft.color,
              aircraft_id: response.aircraft.id,
              expected_flight_time: response.expected_flight_time,
              comment: response.comment,
              draggable: false
            };
          }
        } else if (action === 'edit') {
          const response = 
            await fetch(process.env.REACT_APP_API_URL + '/me/company/bookings/' + event.event_id, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Auth.token()}`
              },
              body: JSON.stringify({
                start: event.start,
                end: event.end,
                aircraft_id: event.aircraft_id,
                expected_flight_time: event.expected_flight_time,
                comment: event.comment,
                seats: event.seats || 0
              })
            })
            .then(response => response.json())
            .catch((error) => {
              console.error('Error:', error)
            });

          if (response.id) {
            return {
              disabled: false,
              event_id: response.id,
              title: response.user.name + ' (' + response.aircraft.name + ')',
              start: new Date(response.start),
              end: new Date(response.end),
              color: response.aircraft.color,
              aircraft_id: response.aircraft.id,
              expected_flight_time: response.expected_flight_time,
              comment: response.comment,
            };
          }
        }

        return {};
      }}
      onDelete={async (id) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/me/company/bookings/' + id, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Auth.token()}`
          }
        });

        if (response.status === 204) {
          return id;
        }

        return false;
      }}
      fields={[
        {
          name: 'title',
          type: 'input',
          default: Auth.user().name,
          config: {
            label: 'Title',
            required: true,
            disabled: true,
            errMsg: 'Udfyld venligst title'
          }
        },
        {
          name: 'aircraft_id',
          type: 'select',
          options: aircraftData.map(aircraft => {
            return {
              id: aircraft.id,
              value: aircraft.id,
              text: aircraft.name
            };
          }),
          config: {
            label: 'Luftfartøj',
            required: true,
            errMsg: 'Udfyld venligst luftfartøj'
          }
        },
        {
          name: "expected_flight_time",
          type: "input",
          config: { label: "Forventet flyvetid", required: true, errMsg: "Udfyld venligst forventet flyvetid" }
        },
        {
          name: "comment",
          type: "input",
          config: { label: "Note", multiline: true, rows: 4 }
        },
        {
          name: 'seats',
          type: 'input',
          default: 0,
          config: {
            label: 'Antal sæder ledige',
            required: false
          }
        }
      ]}
    />
  );
};

export default Booking;
import React, { useState } from 'react';
import Auth from '../components/Auth';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../contexts/SnackbarContext';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Typography, Button } from '@mui/material';

const Navbar = ({ onToggleDarkMode }) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('company');
    showSnackbar('Du er nu logget ud', 'success');
    navigate('/logind');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {process.env.REACT_APP_COMPANY_NAME}
          {Auth.isLoggedIn() && ' - ' + Auth.company().name}
        </Typography>

        {Auth.isLoggedIn() ? (
          <>
            <Button 
                color="inherit" 
                component={Link} 
                to="/dashboard" 
                style={{ fontWeight: isActive('/dashboard') ? 'bold' : 'normal' }}
            >
                Dashboard
            </Button>
            <Button 
                color="inherit" 
                component={Link} 
                to="/booking" 
                style={{ fontWeight: isActive('/booking') ? 'bold' : 'normal' }}
            >
                Booking
            </Button>
            <Button 
                color="inherit" 
                component={Link} 
                to="/flyve-log" 
                style={{ fontWeight: isActive('/flyve-log') ? 'bold' : 'normal' }}
            >
                Flyvelog
            </Button>

            <IconButton color="secondary" onClick={handleMenuOpen}>
              <AccountCircle sx={{ fontSize: '1.5em' }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem component={Link} to="/profil-indstillinger" onClick={handleMenuClose}>
                Profil indstillinger
              </MenuItem>
              <MenuItem component={Link} to="/klub-indstillinger" onClick={handleMenuClose}>
                Klub indstillinger
              </MenuItem>
              <MenuItem onClick={onToggleDarkMode}>Dark mode</MenuItem>
              <MenuItem onClick={handleLogout}>Log ud</MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button color="inherit" component={Link} to="/logind">
              Login
            </Button>
            <Button color="inherit" component={Link} to="/opret-klub">
              Opret klub
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

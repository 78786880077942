import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Grid, CircularProgress, Paper } from '@mui/material';
import { useSnackbar } from '../contexts/SnackbarContext';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    setError('');
    setLoading(true);

    fetch(process.env.REACT_APP_API_URL + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then(response => response.json())
      .then(async data => {
        if (data.token && data.user && data.company) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('company', JSON.stringify(data.company));
          showSnackbar('Velkommen ' + data.user.name, 'success');
          navigate('/dashboard');
        }
      })
      .catch(error => {
        console.error('Login', error);
        setError('Der opstod en fejl under login, kontakt venligst support.');
        setLoading(false);
      });
  };

  return (
    <Container>
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 4 }}>
            <Box sx={{ width: '100%' }}>
              <Typography component="h1" variant="h5" mb={1}>
                Log ind
              </Typography>
              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email adresse"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                  disabled={loading}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Adgangskode"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                  disabled={loading}
                />

                <Button type="submit" size="large" fullWidth variant="contained" color="primary" sx={{ mt: 2, mb: 2 }} disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : 'Log ind'}
                </Button>
                {error && (
                  <Typography variant="body2" color="error">
                    {error}
                  </Typography>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;

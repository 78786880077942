import React, { useState } from 'react';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './components/Navbar';
import ProtectedRoute from './components/ProtectedRoute';
import { SnackbarProvider } from './contexts/SnackbarContext';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import StartPage from './pages/StartPage';
import CreateAccount from './pages/CreateAccount';
import AccountSettings from './pages/AccountSettings';
import CompanySettings from './pages/CompanySettings';
import Booking from './pages/Booking';
import JourneyLog from './pages/JourneyLog';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#194a7a',
      },
      secondary: {
        main: '#f7f4e3',
      },
      background: {
        default: darkMode ? '#121212' : '#d1dbe4', // Set the default background color
        paper: darkMode ? '#1d1d1d' : '#f5f5f5', // Set the background color for paper components
      },
    }
  });

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <Router>
          <Navbar onToggleDarkMode={toggleDarkMode} />
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route 
              path="/logind" 
              element={
                <ProtectedRoute type="Guest">
                  <Login />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/opret-klub" 
              element={
                <ProtectedRoute type="Guest">
                  <CreateAccount />
                </ProtectedRoute>
              } 
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute type="Auth">
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profil-indstillinger"
              element={
                <ProtectedRoute type="Auth">
                  <AccountSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/klub-indstillinger"
              element={
                <ProtectedRoute type="Auth">
                  <CompanySettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/booking"
              element={
                <ProtectedRoute type="Auth">
                  <Booking />
                </ProtectedRoute>
              }
            />
            <Route
              path="/flyve-log"
              element={
                <ProtectedRoute type="Auth">
                  <JourneyLog />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;




import React from 'react';
import Auth from './Auth';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, type }) => {
  if (type === 'Guest' && Auth.isLoggedIn()) {
    return <Navigate to="/dashboard" />; // Redirect logged-in users to dashboard or another route
  }

  if (type === 'Auth' && !Auth.isLoggedIn()) {
    return <Navigate to="/logind" />; // Redirect non-logged-in users to login
  }

  return children;
};

export default ProtectedRoute;

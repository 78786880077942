import React from 'react';
import { Container, Button, Typography, Box, Grid, Card, CardContent, CardMedia } from '@mui/material';

const StartPage = () => {
  return (
    <Container maxWidth="lg">
      {/* Logo and Introduction */}
      <Box textAlign="center" my={4}>
        <img src="android-chrome-192x192.png" alt="Product Logo" style={{ width: '150px' }} />
        <Typography variant="h4" component="h1" gutterBottom>
          Velkommen til BookFly.dk
        </Typography>
        <Typography variant="h6" component="p" color="textSecondary">
          Det bedste værktøj til at booke fly i din forening.
        </Typography>
        <Button variant="contained" color="primary" size="large" style={{ marginTop: '20px' }}>
          Get Started
        </Button>
      </Box>

      {/* Features Section */}
      <Box my={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Features
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h3">
                  Booking
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Book! Book! Book!
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h3">
                  Log flyvning
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Log! Log! Log!
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h3">
                  Mere på vej!
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Jeg arbejder på flere features!
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Video Section */}
      {/* <Box my={4} textAlign="center">
        <Typography variant="h5" component="h2" gutterBottom>
          Watch Our Product in Action
        </Typography>
        <Card>
          <CardMedia
            component="iframe"
            src="https://www.youtube.com/embed/dQw4w9WgXcQ"
            title="Product Video"
            height="400"
          />
        </Card>
      </Box>

      {/* About Section */}
      {/* <Box my={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          About Our Product
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Our product is designed to provide the best experience for our users. It offers a range of features that
          are tailored to meet your needs. Whether you are looking for efficiency, reliability, or innovation, our
          product has it all. Join us on this journey and discover the future of technology with our product.
        </Typography>
      </Box> */}
    </Container>
  );
};

export default StartPage;
import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, List, ListItem, Card, CardContent, Skeleton } from '@mui/material';
import { format } from 'date-fns';
import Auth from '../components/Auth';
import FlightIcon from '@mui/icons-material/Flight';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CommentIcon from '@mui/icons-material/Comment';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [noBookings, setNoBookings] = useState(false);
  const [bookings, setBookings] = useState([]);

  const news = [
    { title: 'Velkommen til BookFly!', content: 'Det en ny portal, så sørg for at fortæl om alle fejl.' },
  ];

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/me/bookings', {
      headers: {
        'Authorization': `Bearer ${Auth.token()}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.length === 0) {
          setNoBookings(true);
        } else {
          setBookings(data);
          setNoBookings(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      }).finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Container sx={{ my: 4 }}>
      <Box sx={{ my: 4 }}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h3" color="primary" component="h1" m="0" gutterBottom>
              Hej {Auth.user().name}
            </Typography>
            <Typography variant="subtitle1" color="primary" sx={{ textTransform: 'uppercase' }}>
              Velkommen til {Auth.company().name}'s portal
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} container justifyContent="flex-end">
            {/* <Button size="large" variant="contained" color="primary" onClick={() => {
              
            }}>
              Lorem
            </Button> */}
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <Typography variant="h4" color="primary" component="h2" m="0" gutterBottom>
            Dine bookinger
          </Typography>
          <List>
            {noBookings ? (
              <Typography variant="h6" color="text.secondary">
                No bookings
              </Typography>
            ) : isLoading ? (
              Array.from(new Array(5)).map((_, index) => (
                <ListItem key={index} disableGutters>
                  <Card variant="outlined" sx={{ width: '100%' }}>
                    <CardContent>
                      <Skeleton variant="text" width="60%" />
                      <Skeleton variant="text" width="40%" />
                    </CardContent>
                  </Card>
                </ListItem>
              ))
            ) : (
              bookings.map((booking, index) => (
                <ListItem key={index} disableGutters>
                  <Card variant="outlined" sx={{ width: '100%', mb: 2 }}>
                    <CardContent>
                      <Typography variant="h6" component="div" gutterBottom>
                        {format(new Date(booking.start), 'd. MMMM yyyy')}
                      </Typography>
                      <Box display="flex" alignItems="center" mb={1}>
                        <FlightIcon sx={{ mr: 1 }} />
                        <Typography variant="body1" color="text.secondary">
                          {booking.aircraft.name}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mb={1}>
                        <AccessTimeIcon sx={{ mr: 1 }} />
                        <Typography variant="body1" color="text.secondary">
                          {booking.start} - {booking.end}
                        </Typography>
                      </Box>
                      {booking.comment && (
                        <Box display="flex" alignItems="center" mb={1}>
                          <CommentIcon sx={{ mr: 1 }} />
                          <Typography variant="body1" color="text.secondary">
                            {booking.comment}
                          </Typography>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </ListItem>
              ))
            )}
          </List>
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="h4" color="primary" component="h2" m="0" gutterBottom>
            Nyheder
          </Typography>
          <List>
            {news.map((booking, index) => (
              <ListItem key={index} disableGutters>
                <Card variant="outlined" sx={{ width: '100%' }}>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {booking.title}
                    </Typography>
                    <Typography color="text">
                      {booking.content}
                    </Typography>
                  </CardContent>
                </Card>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>

    </Container>
  );
};

export default Dashboard;

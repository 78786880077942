import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Grid, CircularProgress, Paper } from '@mui/material';
import { useSnackbar } from '../contexts/SnackbarContext';

const CreateAccount = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({ company_name: '', email: '', password: '', password_confirm: '', name: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState('');
  const { showSnackbar } = useSnackbar();

  const handleSubmit = (event) => {
    event.preventDefault();
    setError('');
    setLoading(true);

    if (userData.password !== userData.password_confirm) {
      setPasswordConfirmError('Adgangskoderne er ikke ens');
      setLoading(false);
      return;
    }
    
    fetch(process.env.REACT_APP_API_URL + '/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: 
        JSON.stringify(userData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.token && data.user && data.company) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('company', JSON.stringify(data.company));
          showSnackbar('Velkommen ' + data.user.name, 'success');
          navigate('/dashboard');
        } else {
          console.error('Create Account', data);
          // Get error message from each in data.
          let errorMessage = '';
          for (const key in data) {
            errorMessage += data[key][0] + '\r\n';
          }
          setError('Udfyld alle felter og prøve igen:\r\n' + errorMessage);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Create Account', error);
        setError('Der opstod en fejl under oprettelse af virksomheden, kontakt venligst support.');
        setLoading(false)
      });
  };

  const handlePasswordConfirmChange = (event) => {
    const value = event.target.value;
    if (value !== userData.password) {
      setPasswordConfirmError('Adgangskoderne er ikke ens');
    } else {
      setPasswordConfirmError('');
      setUserData({ ...userData, password_confirm: value })
    }
  };

  return (
    <Container>
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 4 }}>
            <Box sx={{ width: '100%' }}>
              <Typography component="h1" variant="h5" mb={1}>
                Opret klub
              </Typography>
              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="company_name"
                  label="Klubnavn"
                  name="company_name"
                  autoComplete="company_name"
                  value={userData.company_name}
                  onChange={(e) => setUserData({ ...userData, company_name: e.target.value })}
                  disabled={loading}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Dit navn"
                  name="name"
                  autoComplete="name"
                  value={userData.name}
                  onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                  disabled={loading}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email adresse"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={userData.email}
                  onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                  disabled={loading}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Adgangskode"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  value={userData.password}
                  onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                  disabled={loading}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password_confirm"
                  label="Bekræft adgangskode"
                  name="password_confirm"
                  type="password"
                  autoComplete="new-password"
                  onChange={handlePasswordConfirmChange}
                  error={!!passwordConfirmError}
                  helperText={passwordConfirmError}
                  disabled={loading}
                />

                <Button type="submit" size="large" fullWidth variant="contained" color="primary" sx={{ mt: 2, mb: 2 }} disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : 'Opret klub'}
                </Button>
                {error && (
                  <Typography variant="body2" color="error">
                    {error}
                  </Typography>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreateAccount;
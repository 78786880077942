import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, 
  Paper,
  Typography, 
  TextField,
  Switch,
  FormControlLabel,
  FormGroup,
  Grid,
  Button, 
  Box, 
  Tabs, 
  Tab, 
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete
} from '@mui/material';
import Auth from '../components/Auth';
import { useSnackbar } from '../contexts/SnackbarContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import dayjs from 'dayjs';

const JourneyLog = () => {
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [journeyLogs, setJourneyLogs] = useState([]);
  const { showSnackbar } = useSnackbar();
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [aircraftData, setAircraftData] = useState([]);
  const [airports, setAirports] = useState([]);
  const [activeAircraftId, setActiveAircraftId] = useState(null);

  const [errorLandingTime, setErrorLandingTime] = useState('');
  const [errorEndTachoTime, setErrorEndTachoTime] = useState('');

  const handleTabChange = (event, key) => {
    const id = event.currentTarget.id;
    setActiveStep(key);
    loadJournalLogs(id);
  };

  const loadJournalLogs = useCallback((aircraftId) => {
    setLoading(true);
    setActiveAircraftId(aircraftId);
    fetch(process.env.REACT_APP_API_URL + '/me/company/aircraft/' + aircraftId + '/journey_logs', {
      headers: {
        'Authorization': `Bearer ${Auth.token()}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.length !== 0) {
          setJourneyLogs(data);
          setNoData(false);
        } else {
          setJourneyLogs([]); // Clear the table if there are
          setNoData(true);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        showSnackbar('Failed to load flight logs', { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showSnackbar]);

  const fetchAircraftData = useCallback((setAircraftId = null) => {
    fetch(`${process.env.REACT_APP_API_URL}/me/company/aircraft`, {
      headers: {
        'Authorization': `Bearer ${Auth.token()}`
      }
    })
    .then(response => response.json())
    .then(data => {
      setAircraftData(data);
      if (data.length !== 0) {
        loadJournalLogs(setAircraftId ?? data[0].id);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      showSnackbar('Failed to load aircraft data', { variant: 'error' });
    });
  }, [showSnackbar, loadJournalLogs]);

  useEffect(() => {
    fetchAircraftData();
  }, [fetchAircraftData]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/airports', {
      headers: {
        'Authorization': `Bearer ${Auth.token()}`
      }
    })
      .then(response => response.json())
      .then(data => {
        setAirports(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const handleFlightLogNew = () => {
    const aircraft = {
      id: null, 
      date: dayjs(new Date()),
      start_tacho_time: aircraftData.find(aircraft => parseInt(aircraft.id) === parseInt(activeAircraftId))?.total_tacho_time ?? 0,
      end_tacho_time: aircraftData.find(aircraft => parseInt(aircraft.id) === parseInt(activeAircraftId))?.total_tacho_time ?? 0,
      airport_from_id: aircraftData.find(aircraft => parseInt(aircraft.id) === parseInt(activeAircraftId))?.current_airport_id ?? '',
      airport_to_id: aircraftData.find(aircraft => parseInt(aircraft.id) === parseInt(activeAircraftId))?.current_airport_id ?? '',
      landings: 1,
    };

    setSelectedJourney(aircraft);
  };

  const handleFlightLogEdit = (aircraft) => {
    // aircraft.date = dayjs(aircraft.date);
    // aircraft.takeoff_time = dayjs(aircraft.takeoff_time);
    // aircraft.landing_time = dayjs(aircraft.landing_time);
    
    const aircraftObj = {
      id: aircraft.id, 
      date: dayjs(aircraft.date),
      completed_daily_check: aircraft.completed_daily_check,
      added_fuel: aircraft.added_fuel,
      added_oil: aircraft.added_oil,
      airport_from_id: aircraft.airport_from_id,
      airport_to_id: aircraft.airport_to_id,
      takeoff_time: dayjs(new Date('2021-01-01T' + aircraft.takeoff_time)),
      landing_time: dayjs(new Date('2021-01-01T' + aircraft.landing_time)),
      start_tacho_time: aircraft.start_tacho_time,
      end_tacho_time: aircraft.end_tacho_time,
      landings: aircraft.landings,
      comment: aircraft.comment,
    };

    setSelectedJourney(aircraftObj);
  };

  const handleJourneySave = async () => {
    setLoading(true);
    const method = selectedJourney.id ? 'PATCH' : 'POST';
    fetch(process.env.REACT_APP_API_URL + '/me/company/aircraft/' + activeAircraftId + '/journey_logs' + (method === 'PATCH' ? `/${selectedJourney.id}` : ''), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Auth.token()}`
      },
      body: JSON.stringify(selectedJourney),
    })
      .then(response => response.json())
      .then(data => {
        if (data.id) {
          showSnackbar('Flyvelog er ' + (method === 'PATCH' ? 'gemt' : 'oprettet'), 'success');
        } else {
          showSnackbar('Der opstod en fejl under gemning af flyvelog', 'error');
        }
      }).finally(() => {
        fetchAircraftData(activeAircraftId); // This will reload the flight logs for the selected aircraft.
      });

    setSelectedJourney(null);
  };

  const formatTime = (time) => {
    return time ? time.slice(0, 5) : '';
  };

  const formatMinutes = (minutes) => {
    // minutes could be 90 (1 hour and 30 minutes), format as 1h 30min
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    
    return `${hours}t ${mins > 0 ? `${mins}m` : ''}`;
  }

  const checkLandingTimeRule = (landingTime, takeoffTime) => {
    if (!landingTime || !takeoffTime) return;
    
    let lt = dayjs(landingTime);
    let tt = dayjs(takeoffTime);

    if (lt.isBefore(tt)) {
      setErrorLandingTime('Landingstid må ikke være før afgangstid');
    } else {
      setErrorLandingTime('');
    }
  };

  const checkEndTachoTimeRule = (endTachoTime, startTachoTime) => {
    if (!endTachoTime || !startTachoTime) return;

    if (endTachoTime < startTachoTime) {
      setErrorEndTachoTime('Slut tacho må ikke være mindre end start tacho');
    } else {
      setErrorEndTachoTime('');
    }
  };
  
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Grid container alignItems="center">
          <Grid item xs={6} sm={8}>
            <Typography variant="h4" color="primary" component="h1" m="0" gutterBottom>
              Flyvelog
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} container justifyContent="flex-end">
            <Button size="large" variant="contained" color="primary" onClick={() => handleFlightLogNew() } disabled={loading}>
              Log flyvning
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Tabs value={activeStep} onChange={handleTabChange} aria-label="settings tabs" sx={{ mb: 2 }}>
        {aircraftData.map((aircraft) => (
            <Tab id={aircraft.id} key={aircraft.id} label={aircraft.name} />
        ))}
      </Tabs>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: 0 }}></TableCell>
              <TableCell sx={{ borderBottom: 0 }}></TableCell>
              <TableCell sx={{ borderBottom: 0 }}></TableCell>
              <TableCell colSpan={2} align="center" sx={{ borderBottom: 0, p: 0, pt: 1, fontSize: '0.8em', fontWeight: 'bold', borderLeft: 1, borderColor: 'rgb(224, 224, 224)' }}>Påfyldning</TableCell>
              <TableCell colSpan={2} align="center" sx={{ borderBottom: 0, p: 0, pt: 1, fontSize: '0.8em', fontWeight: 'bold', borderLeft: 1, borderColor: 'rgb(224, 224, 224)' }}>Lufthavn</TableCell>
              <TableCell colSpan={4} align="center" sx={{ borderBottom: 0, p: 0, pt: 1, fontSize: '0.8em', fontWeight: 'bold', borderLeft: 1, borderColor: 'rgb(224, 224, 224)' }}>Tid</TableCell>
              <TableCell colSpan={3} align="center" sx={{ borderBottom: 0, p: 0, pt: 1, fontSize: '0.8em', fontWeight: 'bold', borderLeft: 1, borderRight: 1, borderColor: 'rgb(224, 224, 224)' }}>Tacho</TableCell>
              <TableCell sx={{ borderBottom: 0 }}></TableCell>
              <TableCell sx={{ borderBottom: 0 }}></TableCell>
              <TableCell sx={{ borderBottom: 0 }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ pb: 1, pt: 0 }}>Dato</TableCell>
              <TableCell sx={{ pb: 1, pt: 0 }}>Medlem</TableCell>
              <TableCell sx={{ pb: 1, pt: 0 }}>Tjek</TableCell>
              <TableCell sx={{ pb: 1, pt: 0, borderLeft: 1, borderColor: 'rgb(224, 224, 224)' }}>Brændstof</TableCell>
              <TableCell sx={{ pb: 1, pt: 0 }}>Olie</TableCell>
              <TableCell sx={{ pb: 1, pt: 0, borderLeft: 1, borderColor: 'rgb(224, 224, 224)' }}>Start</TableCell>
              <TableCell sx={{ pb: 1, pt: 0 }}>Slut</TableCell>
              <TableCell sx={{ pb: 1, pt: 0, borderLeft: 1, borderColor: 'rgb(224, 224, 224)' }}>Afgang</TableCell>
              <TableCell sx={{ pb: 1, pt: 0 }}>Ankomst</TableCell>
              <TableCell sx={{ pb: 1, pt: 0 }}>Flyvetid</TableCell>
              <TableCell sx={{ pb: 1, pt: 0 }}>Total</TableCell>
              <TableCell sx={{ pb: 1, pt: 0, borderLeft: 1, borderColor: 'rgb(224, 224, 224)' }}>Start</TableCell>
              <TableCell sx={{ pb: 1, pt: 0 }}>Slut</TableCell>
              <TableCell sx={{ pb: 1, pt: 0, borderRight: 1, borderColor: 'rgb(224, 224, 224)' }}>Brugt</TableCell>
              <TableCell sx={{ pb: 1, pt: 0 }}>Landinger</TableCell>
              <TableCell sx={{ pb: 1, pt: 0 }}>Note</TableCell>
              <TableCell sx={{ pb: 1, pt: 0 }}>Bemærkning</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || noData ? (
              <TableRow>
                <TableCell colSpan={18} sx={{ textAlign: 'center' }}>
                  {loading ? 'Indlæser' : 'Der er ingen flyvelog endnu, opret det første!'}
                </TableCell>
              </TableRow>
            ) : (
              journeyLogs.map((journeyLog) => (
                <TableRow key={journeyLog.id} onClick={() => handleFlightLogEdit(journeyLog)}>
                  <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{journeyLog.date}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{journeyLog.user.name}</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {journeyLog.completed_daily_check ? <CheckCircleIcon color="primary" style={{color: 'green' }} /> : <CancelIcon color="error" style={{color: '#eee'}} />}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center', borderLeft: 1, borderColor: 'rgb(224, 224, 224)' }}>{journeyLog.added_fuel.toFixed(2)}</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>{journeyLog.added_oil.toFixed(1)}</TableCell>
                  <TableCell sx={{ borderLeft: 1, borderColor: 'rgb(224, 224, 224)' }}>{journeyLog.airport_from.icao_code}</TableCell>
                  <TableCell>{journeyLog.airport_to.icao_code}</TableCell>
                  <TableCell sx={{ borderLeft: 1, borderColor: 'rgb(224, 224, 224)' }}>{formatTime(journeyLog.takeoff_time)}</TableCell>
                  <TableCell>{formatTime(journeyLog.landing_time)}</TableCell>
                  <TableCell>{formatMinutes(journeyLog.flight_time)}</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>{journeyLog.total_flight_time}</TableCell>
                  <TableCell sx={{ textAlign: 'right', borderLeft: 1, borderColor: 'rgb(224, 224, 224)' }}>{journeyLog.start_tacho_time}</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>{journeyLog.end_tacho_time}</TableCell>
                  <TableCell sx={{ borderRight: 1, borderColor: 'rgb(224, 224, 224)' }}>{journeyLog.tacho_time}</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>{journeyLog.landings}</TableCell>
                  <TableCell>{journeyLog.comment}</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>{journeyLog.remarks ? 'Ja' : 'Nej'}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={!!selectedJourney} onClose={() => setSelectedJourney(null)}>
        <DialogTitle>
          {selectedJourney && selectedJourney.id ? 'Rediger log' : 'Opret log'}
        </DialogTitle>
        <DialogContent>
          {selectedJourney && (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="da-DK">
              <MobileDatePicker
                required
                isRequired="true"
                label="Dato *"
                sx={{ mt: 2}}
                format="DD/MM/YYYY"
                value={selectedJourney.date}
                slotProps={{ textField: { fullWidth: true } }}
                onChange={(value, context) => setSelectedJourney({ ...selectedJourney, date: value })}
                disabled={loading}
              />
              <FormGroup sx={{ my: 2 }}>
                <FormControlLabel control={
                  <Switch
                    label="Udført daglig check"
                    checked={selectedJourney.completed_daily_check ?? false}
                    onChange={(e) => setSelectedJourney({ ...selectedJourney, completed_daily_check: e.target.checked })}
                    disabled={loading}
                  />
                } label="Udført daglig check" />
              </FormGroup>
              <fieldset style={{ border: '1px solid #ccc', padding: '0 10px 10px 10px', borderRadius: '5px' }}>
                <legend style={{ padding: '0 10px' }}>Påfyldning</legend>
                <TextField
                  fullWidth
                  sx={{ my: 1 }}
                  label="Brændstof"
                  type="number"
                  value={selectedJourney.added_fuel ?? ''}
                  onChange={(e) => setSelectedJourney({ ...selectedJourney, added_fuel: e.target.value.replace(',', '.') })}
                  disabled={loading}
                />
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  label="Olie"
                  type="number"
                  value={selectedJourney.added_oil ?? ''}
                  onChange={(e) => setSelectedJourney({ ...selectedJourney, added_oil: e.target.value.replace(',', '.') })}
                  disabled={loading}
                />
              </fieldset>
              <fieldset style={{ border: '1px solid #ccc', padding: '0 10px 10px 10px', borderRadius: '5px', marginTop: '15px' }}>
                <legend style={{ padding: '0 10px' }}>Lufthavn</legend>
                <Autocomplete
                  disablePortal
                  sx={{ my: 1 }}
                  options={airports}
                  value={airports.find((airport) => airport.id === selectedJourney.airport_from_id) || null}
                  getOptionLabel={(option) => option.icao_code ?? ''}
                  getOptionKey={(option) => option.id}
                  onChange={(e, newValue) => {
                    setSelectedJourney({ ...selectedJourney, airport_from_id: newValue?.id ?? '' });
                  }}
                  renderInput={(params) => <TextField {...params} label="Fra" required />}
                />
                <Autocomplete
                  disablePortal
                  sx={{ my: 1 }}
                  options={airports}
                  value={airports.find((airport) => airport.id === selectedJourney.airport_to_id) || null}
                  getOptionLabel={(option) => option.icao_code}
                  getOptionKey={(option) => option.id}
                  onChange={(e, newValue) => {
                    setSelectedJourney({ ...selectedJourney, airport_to_id: newValue?.id ?? '' });
                  }}
                  renderInput={(params) => <TextField {...params} label="Til" required />}
                />
              </fieldset>
              <fieldset style={{ border: '1px solid #ccc', padding: '0 10px 10px 10px', borderRadius: '5px', marginTop: '15px' }}>
                <legend style={{ padding: '0 10px' }}>Flyvetid</legend>
                <MobileTimePicker
                  required
                  fullWidth
                  ampm={false}
                  sx={{ my: 1 }}
                  label="Afgang *"
                  value={selectedJourney.takeoff_time ?? null}
                  onChange={(value, context) => {
                    setSelectedJourney({ ...selectedJourney, takeoff_time: value });
                    checkLandingTimeRule(selectedJourney.landing_time, value);
                  }}
                  disabled={loading}
                  slotProps={{ textField: { fullWidth: true } }}
                />
                <MobileTimePicker
                  required
                  fullWidth
                  ampm={false}
                  sx={{ mb: 1 }}
                  margin="normal"
                  label="Ankomst *"
                  value={selectedJourney.landing_time ?? null}
                  onChange={(value, context) => {
                    setSelectedJourney({ ...selectedJourney, landing_time: value });
                    checkLandingTimeRule(value, selectedJourney.takeoff_time);
                  }}
                  disabled={loading}
                  slotProps={{ textField: { fullWidth: true, error: !!errorLandingTime, helperText: errorLandingTime } }}
                />
              </fieldset>
              <fieldset style={{ border: '1px solid #ccc', padding: '0 10px 10px 10px', borderRadius: '5px', marginTop: '15px' }}>
                <legend style={{ padding: '0 10px' }}>Tacho</legend>
                <TextField
                  required
                  fullWidth
                  sx={{ my: 1 }}
                  label="Start"
                  type="number"
                  value={selectedJourney.start_tacho_time ?? ''}
                  onChange={(e) => {
                    setSelectedJourney({ ...selectedJourney, start_tacho_time: e.target.value });
                    checkEndTachoTimeRule(selectedJourney.end_tacho_time, e.target.value);
                  }}
                  disabled={loading}
                />
                <TextField
                  required
                  fullWidth
                  sx={{ mb: 1 }}
                  label="Slut"
                  type="number"
                  value={selectedJourney.end_tacho_time ?? ''}
                  onChange={(e) => {
                    setSelectedJourney({ ...selectedJourney, end_tacho_time: e.target.value });
                    checkEndTachoTimeRule(e.target.value, selectedJourney.start_tacho_time);
                  }}
                  error={!!errorEndTachoTime}
                  helperText={errorEndTachoTime}
                  disabled={loading}
                />
              </fieldset>
              <TextField
                required
                fullWidth
                margin="normal"
                label="Landinger"
                type="number"
                value={selectedJourney.landings ?? 1}
                onChange={(e) => setSelectedJourney({ ...selectedJourney, landings: e.target.value })}
                disabled={loading}
                inputProps={{ min: 1 }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Note"
                type="text"
                value={selectedJourney.comment ?? ''}
                onChange={(e) => setSelectedJourney({ ...selectedJourney, comment: e.target.value })}
                disabled={loading}
              />
            </LocalizationProvider>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedJourney(null)} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleJourneySave} color="primary" disabled={!!errorLandingTime || !!errorEndTachoTime || loading}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default JourneyLog;